%svg__default {
    background-image: url("/static/caas/3.1.2/Responsive/Icons/default.svg?cachebust=1739538117045");
    background-repeat: no-repeat;
    background-size: 25.25em 25.25em;
    display: inline-block;
}

.image-rating-0-0 {
    @extend %svg__default;
    background-position: -0.25em -0.25em;
    width: 5.625em;
    height: 1em;
}
.image-rating-1-0 {
    @extend %svg__default;
    background-position: -0.25em -1.5em;
    width: 5.625em;
    height: 1em;
}
.image-rating-1-5 {
    @extend %svg__default;
    background-position: -0.25em -2.75em;
    width: 5.625em;
    height: 1em;
}
.image-rating-2-0 {
    @extend %svg__default;
    background-position: -0.25em -4em;
    width: 5.625em;
    height: 1em;
}
.image-rating-2-5 {
    @extend %svg__default;
    background-position: -6.125em -0.25em;
    width: 5.625em;
    height: 1em;
}
.image-rating-3-0 {
    @extend %svg__default;
    background-position: -6.125em -1.5em;
    width: 5.625em;
    height: 1em;
}
.image-rating-3-5 {
    @extend %svg__default;
    background-position: -6.125em -2.75em;
    width: 5.625em;
    height: 1em;
}
.image-rating-4-0 {
    @extend %svg__default;
    background-position: -6.125em -4em;
    width: 5.625em;
    height: 1em;
}
.image-rating-4-5 {
    @extend %svg__default;
    background-position: -0.25em -5.25em;
    width: 5.625em;
    height: 1em;
}
.image-rating-5-0 {
    @extend %svg__default;
    background-position: -6.125em -5.25em;
    width: 5.625em;
    height: 1em;
}
.other-gamereactor {
    @extend %svg__default;
    background-position: -0.25em -6.5em;
    width: 4.6875em;
    height: 1em;
}
.energy-6-0 {
    @extend %svg__default;
    background-position: -5.1875em -6.5em;
    width: 2em;
    height: 1em;
}
.energy-6-1 {
    @extend %svg__default;
    background-position: -7.4375em -6.5em;
    width: 2em;
    height: 1em;
}
.energy-6-2 {
    @extend %svg__default;
    background-position: -9.6875em -6.5em;
    width: 2em;
    height: 1em;
}
.energy-6-3 {
    @extend %svg__default;
    background-position: -0.25em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-6-4 {
    @extend %svg__default;
    background-position: -2.5em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-6-5 {
    @extend %svg__default;
    background-position: -4.75em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-6-6 {
    @extend %svg__default;
    background-position: -7em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-6-7 {
    @extend %svg__default;
    background-position: -9.25em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-6-8 {
    @extend %svg__default;
    background-position: -0.25em -9em;
    width: 2em;
    height: 1em;
}
.energy-6-9 {
    @extend %svg__default;
    background-position: -2.5em -9em;
    width: 2em;
    height: 1em;
}
.energy-7-0 {
    @extend %svg__default;
    background-position: -4.75em -9em;
    width: 2em;
    height: 1em;
}
.energy-7-1 {
    @extend %svg__default;
    background-position: -7em -9em;
    width: 2em;
    height: 1em;
}
.energy-7-2 {
    @extend %svg__default;
    background-position: -9.25em -9em;
    width: 2em;
    height: 1em;
}
.energy-7-3 {
    @extend %svg__default;
    background-position: -0.25em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-7-4 {
    @extend %svg__default;
    background-position: -2.5em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-7-5 {
    @extend %svg__default;
    background-position: -4.75em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-7-6 {
    @extend %svg__default;
    background-position: -7em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-7-7 {
    @extend %svg__default;
    background-position: -9.25em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-7-8 {
    @extend %svg__default;
    background-position: -12em -0.25em;
    width: 2em;
    height: 1em;
}
.energy-7-9 {
    @extend %svg__default;
    background-position: -12em -1.5em;
    width: 2em;
    height: 1em;
}
.energy-8-0 {
    @extend %svg__default;
    background-position: -12em -2.75em;
    width: 2em;
    height: 1em;
}
.energy-8-1 {
    @extend %svg__default;
    background-position: -12em -4em;
    width: 2em;
    height: 1em;
}
.energy-8-2 {
    @extend %svg__default;
    background-position: -12em -5.25em;
    width: 2em;
    height: 1em;
}
.energy-8-3 {
    @extend %svg__default;
    background-position: -12em -6.5em;
    width: 2em;
    height: 1em;
}
.energy-8-4 {
    @extend %svg__default;
    background-position: -12em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-8-5 {
    @extend %svg__default;
    background-position: -12em -9em;
    width: 2em;
    height: 1em;
}
.energy-8-6 {
    @extend %svg__default;
    background-position: -12em -10.25em;
    width: 2em;
    height: 1em;
}
.energy-8-7 {
    @extend %svg__default;
    background-position: -0.25em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-8-8 {
    @extend %svg__default;
    background-position: -2.5em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-8-9 {
    @extend %svg__default;
    background-position: -4.75em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-9-0 {
    @extend %svg__default;
    background-position: -7em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-9-1 {
    @extend %svg__default;
    background-position: -9.25em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-9-2 {
    @extend %svg__default;
    background-position: -11.5em -11.5em;
    width: 2em;
    height: 1em;
}
.energy-9-3 {
    @extend %svg__default;
    background-position: -0.25em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-4 {
    @extend %svg__default;
    background-position: -2.5em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-5 {
    @extend %svg__default;
    background-position: -4.75em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-6 {
    @extend %svg__default;
    background-position: -7em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-7 {
    @extend %svg__default;
    background-position: -9.25em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-8 {
    @extend %svg__default;
    background-position: -11.5em -12.75em;
    width: 2em;
    height: 1em;
}
.energy-9-9 {
    @extend %svg__default;
    background-position: -14.25em -0.25em;
    width: 2em;
    height: 1em;
}
.energy-new-0 {
    @extend %svg__default;
    background-position: -14.25em -1.5em;
    width: 2em;
    height: 1em;
}
.energy-new-1 {
    @extend %svg__default;
    background-position: -14.25em -2.75em;
    width: 2em;
    height: 1em;
}
.energy-new-2 {
    @extend %svg__default;
    background-position: -14.25em -4em;
    width: 2em;
    height: 1em;
}
.energy-new-3 {
    @extend %svg__default;
    background-position: -14.25em -5.25em;
    width: 2em;
    height: 1em;
}
.energy-new-4 {
    @extend %svg__default;
    background-position: -14.25em -6.5em;
    width: 2em;
    height: 1em;
}
.energy-new-5 {
    @extend %svg__default;
    background-position: -14.25em -7.75em;
    width: 2em;
    height: 1em;
}
.energy-new-6 {
    @extend %svg__default;
    background-position: -14.25em -9em;
    width: 2em;
    height: 1em;
}
.image-ink {
    @extend %svg__default;
    background-position: -14.25em -10.25em;
    width: 1.71875em;
    height: 1.71875em;
}
.fa-white-gift {
    @extend %svg__default;
    background-position: -14.25em -12.21875em;
    width: 1.15625em;
    height: 1.03125em;
}
.fa-calculator {
    @extend %svg__default;
    background-position: -0.25em -14em;
    width: 1em;
    height: 1em;
}
.fa-info-circle-blue {
    @extend %svg__default;
    background-position: -1.5em -14em;
    width: 1em;
    height: 1em;
}
.fa-info-circle {
    @extend %svg__default;
    background-position: -2.75em -14em;
    width: 1em;
    height: 1em;
}
.fa-truck {
    @extend %svg__default;
    background-position: -4em -14em;
    width: 1em;
    height: 1em;
}
.fa-user {
    @extend %svg__default;
    background-position: -5.25em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-circle-dark-green {
    @extend %svg__default;
    background-position: -6.5em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-circle-o-dark-green {
    @extend %svg__default;
    background-position: -7.75em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-dark-green {
    @extend %svg__default;
    background-position: -9em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-green {
    @extend %svg__default;
    background-position: -10.25em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-lightgreen {
    @extend %svg__default;
    background-position: -11.5em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-check-square-green {
    @extend %svg__default;
    background-position: -12.75em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-circle-dark-blue {
    @extend %svg__default;
    background-position: -14em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-circle-dark-green {
    @extend %svg__default;
    background-position: -15.25em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-circle-green {
    @extend %svg__default;
    background-position: -0.25em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-cloud-download {
    @extend %svg__default;
    background-position: -1.5em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-comments-o-lightgreen {
    @extend %svg__default;
    background-position: -2.75em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-envelope {
    @extend %svg__default;
    background-position: -4em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-exclamation-circle-red {
    @extend %svg__default;
    background-position: -5.25em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-external-link-square-blue {
    @extend %svg__default;
    background-position: -6.5em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-info-blue {
    @extend %svg__default;
    background-position: -7.75em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-list-f5f5f5 {
    @extend %svg__default;
    background-position: -9em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-minus-circle-dark-red {
    @extend %svg__default;
    background-position: -10.25em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-minus-square-dark-red {
    @extend %svg__default;
    background-position: -11.5em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-minus-square-red {
    @extend %svg__default;
    background-position: -12.75em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-minus-square-yellow {
    @extend %svg__default;
    background-position: -14em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-mobile {
    @extend %svg__default;
    background-position: -15.25em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-pause-circle-orange {
    @extend %svg__default;
    background-position: -16.5em -0.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-pause-orange {
    @extend %svg__default;
    background-position: -16.5em -1.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-play-circle-dark-orange {
    @extend %svg__default;
    background-position: -16.5em -2.75em;
    width: 1em;
    height: 1em;
}
.fa-colored-play-circle-orange {
    @extend %svg__default;
    background-position: -16.5em -4em;
    width: 1em;
    height: 1em;
}
.fa-colored-play-orange {
    @extend %svg__default;
    background-position: -16.5em -5.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-reply-green {
    @extend %svg__default;
    background-position: -16.5em -6.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-shopping-bag-red {
    @extend %svg__default;
    background-position: -16.5em -7.75em;
    width: 1em;
    height: 1em;
}
.fa-colored-star-blue {
    @extend %svg__default;
    background-position: -16.5em -9em;
    width: 1em;
    height: 1em;
}
.fa-colored-star-half-o-blue {
    @extend %svg__default;
    background-position: -16.5em -10.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-star-o-blue {
    @extend %svg__default;
    background-position: -16.5em -11.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-th-large-f5f5f5 {
    @extend %svg__default;
    background-position: -16.5em -12.75em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-circle-bluegray {
    @extend %svg__default;
    background-position: -16.5em -14em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-circle-dark-red {
    @extend %svg__default;
    background-position: -16.5em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-circle-gray {
    @extend %svg__default;
    background-position: -0.25em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-circle {
    @extend %svg__default;
    background-position: -1.5em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-dark-red {
    @extend %svg__default;
    background-position: -2.75em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-times-red {
    @extend %svg__default;
    background-position: -4em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-colored-truck-green {
    @extend %svg__default;
    background-position: -5.25em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-double-down {
    @extend %svg__default;
    background-position: -6.5em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-down-wide {
    @extend %svg__default;
    background-position: -7.75em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-down {
    @extend %svg__default;
    background-position: -9em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-left-wide {
    @extend %svg__default;
    background-position: -10.25em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-right-wide {
    @extend %svg__default;
    background-position: -11.5em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-angle-right {
    @extend %svg__default;
    background-position: -12.75em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-bars {
    @extend %svg__default;
    background-position: -14em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-caret-down {
    @extend %svg__default;
    background-position: -15.25em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-caret-left {
    @extend %svg__default;
    background-position: -16.5em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-caret-right {
    @extend %svg__default;
    background-position: -17.75em -0.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-caret-up {
    @extend %svg__default;
    background-position: -17.75em -1.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-check-square-custom {
    @extend %svg__default;
    background-position: -17.75em -2.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-check-square-o {
    @extend %svg__default;
    background-position: -17.75em -4em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-check {
    @extend %svg__default;
    background-position: -17.75em -5.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-chevron-circle-down {
    @extend %svg__default;
    background-position: -17.75em -6.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-chevron-circle-right {
    @extend %svg__default;
    background-position: -17.75em -7.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-chevron-down {
    @extend %svg__default;
    background-position: -17.75em -9em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-chevron-up {
    @extend %svg__default;
    background-position: -17.75em -10.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-circle-o-notch {
    @extend %svg__default;
    background-position: -17.75em -11.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-circle-o {
    @extend %svg__default;
    background-position: -17.75em -12.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-circle {
    @extend %svg__default;
    background-position: -17.75em -14em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-clock-o {
    @extend %svg__default;
    background-position: -17.75em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-close_thin {
    @extend %svg__default;
    background-position: -17.75em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-comments-o {
    @extend %svg__default;
    background-position: -0.25em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-dot-circle-o {
    @extend %svg__default;
    background-position: -1.5em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-download {
    @extend %svg__default;
    background-position: -2.75em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-ellipsis-v {
    @extend %svg__default;
    background-position: -4em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-envelope {
    @extend %svg__default;
    background-position: -5.25em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-exclamation-circle {
    @extend %svg__default;
    background-position: -6.5em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-heart-o {
    @extend %svg__default;
    background-position: -7.75em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-heart {
    @extend %svg__default;
    background-position: -9em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-history {
    @extend %svg__default;
    background-position: -10.25em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-info-circle {
    @extend %svg__default;
    background-position: -11.5em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-list-ul {
    @extend %svg__default;
    background-position: -12.75em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-list {
    @extend %svg__default;
    background-position: -14em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-lock {
    @extend %svg__default;
    background-position: -15.25em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-minus-circle {
    @extend %svg__default;
    background-position: -16.5em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-pause {
    @extend %svg__default;
    background-position: -17.75em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-pencil-square-o {
    @extend %svg__default;
    background-position: -19em -0.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-pencil {
    @extend %svg__default;
    background-position: -19em -1.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-play {
    @extend %svg__default;
    background-position: -19em -2.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-power-off {
    @extend %svg__default;
    background-position: -19em -4em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-question-circle {
    @extend %svg__default;
    background-position: -19em -5.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-search {
    @extend %svg__default;
    background-position: -19em -6.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-share-alt {
    @extend %svg__default;
    background-position: -19em -7.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-shopping-bag {
    @extend %svg__default;
    background-position: -19em -9em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-shopping-basket {
    @extend %svg__default;
    background-position: -19em -10.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-shopping-cart {
    @extend %svg__default;
    background-position: -19em -11.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-square-o {
    @extend %svg__default;
    background-position: -19em -12.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-th-large {
    @extend %svg__default;
    background-position: -19em -14em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-times-circle-o {
    @extend %svg__default;
    background-position: -19em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-times-circle {
    @extend %svg__default;
    background-position: -19em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-times {
    @extend %svg__default;
    background-position: -19em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-tracking {
    @extend %svg__default;
    background-position: -0.25em -19em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-trash-o {
    @extend %svg__default;
    background-position: -1.5em -19em;
    width: 1em;
    height: 1em;
}
.fa-darkgrey-user {
    @extend %svg__default;
    background-position: -2.75em -19em;
    width: 1em;
    height: 1em;
}
.fa-green-shield {
    @extend %svg__default;
    background-position: -4em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-balance-scale {
    @extend %svg__default;
    background-position: -5.25em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-facebook {
    @extend %svg__default;
    background-position: -6.5em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-heart-o {
    @extend %svg__default;
    background-position: -7.75em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-heart {
    @extend %svg__default;
    background-position: -9em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-list {
    @extend %svg__default;
    background-position: -10.25em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-minus-circle {
    @extend %svg__default;
    background-position: -11.5em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-shield {
    @extend %svg__default;
    background-position: -12.75em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-star-o {
    @extend %svg__default;
    background-position: -14em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-star {
    @extend %svg__default;
    background-position: -15.25em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-wishlist-o {
    @extend %svg__default;
    background-position: -16.5em -19em;
    width: 1em;
    height: 1em;
}
.fa-grey-wishlist {
    @extend %svg__default;
    background-position: -17.75em -19em;
    width: 1em;
    height: 1em;
}
.fa-lightgrey-chevron-circle-down {
    @extend %svg__default;
    background-position: -19em -19em;
    width: 1em;
    height: 1em;
}
.fa-lightgrey-chevron-circle-right {
    @extend %svg__default;
    background-position: -20.25em -0.25em;
    width: 1em;
    height: 1em;
}
.fa-lightgrey-times {
    @extend %svg__default;
    background-position: -20.25em -1.5em;
    width: 1em;
    height: 1em;
}
.fa-white-apple {
    @extend %svg__default;
    background-position: -20.25em -2.75em;
    width: 1em;
    height: 1em;
}
.fa-white-at {
    @extend %svg__default;
    background-position: -20.25em -4em;
    width: 1em;
    height: 1em;
}
.fa-white-bell {
    @extend %svg__default;
    background-position: -20.25em -5.25em;
    width: 1em;
    height: 1em;
}
.fa-white-building {
    @extend %svg__default;
    background-position: -20.25em -6.5em;
    width: 1em;
    height: 1em;
}
.fa-white-calendar {
    @extend %svg__default;
    background-position: -20.25em -7.75em;
    width: 1em;
    height: 1em;
}
.fa-white-caret-up {
    @extend %svg__default;
    background-position: -20.25em -9em;
    width: 1em;
    height: 1em;
}
.fa-white-check-circle {
    @extend %svg__default;
    background-position: -20.25em -10.25em;
    width: 1em;
    height: 1em;
}
.fa-white-check {
    @extend %svg__default;
    background-position: -20.25em -11.5em;
    width: 1em;
    height: 1em;
}
.fa-white-chevron-circle-left {
    @extend %svg__default;
    background-position: -20.25em -12.75em;
    width: 1em;
    height: 1em;
}
.fa-white-chevron-circle-right {
    @extend %svg__default;
    background-position: -20.25em -14em;
    width: 1em;
    height: 1em;
}
.fa-white-chevron-down {
    @extend %svg__default;
    background-position: -20.25em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-white-chevron-right {
    @extend %svg__default;
    background-position: -20.25em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-white-cogs {
    @extend %svg__default;
    background-position: -20.25em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-white-comment-o {
    @extend %svg__default;
    background-position: -20.25em -19em;
    width: 1em;
    height: 1em;
}
.fa-white-comment {
    @extend %svg__default;
    background-position: -0.25em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-comments-o {
    @extend %svg__default;
    background-position: -1.5em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-comments {
    @extend %svg__default;
    background-position: -2.75em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-credit-card {
    @extend %svg__default;
    background-position: -4em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-dot-circle-o {
    @extend %svg__default;
    background-position: -5.25em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-download {
    @extend %svg__default;
    background-position: -6.5em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-envelope {
    @extend %svg__default;
    background-position: -7.75em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-exclamation-circle {
    @extend %svg__default;
    background-position: -9em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-exclamation-triangle {
    @extend %svg__default;
    background-position: -10.25em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-file-text-o {
    @extend %svg__default;
    background-position: -11.5em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-globe {
    @extend %svg__default;
    background-position: -12.75em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-hashtag {
    @extend %svg__default;
    background-position: -14em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-heart-o {
    @extend %svg__default;
    background-position: -15.25em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-heart {
    @extend %svg__default;
    background-position: -16.5em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-info-circle {
    @extend %svg__default;
    background-position: -17.75em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-info {
    @extend %svg__default;
    background-position: -19em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-key {
    @extend %svg__default;
    background-position: -20.25em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-list {
    @extend %svg__default;
    background-position: -21.5em -0.25em;
    width: 1em;
    height: 1em;
}
.fa-white-lock {
    @extend %svg__default;
    background-position: -21.5em -1.5em;
    width: 1em;
    height: 1em;
}
.fa-white-map-marker {
    @extend %svg__default;
    background-position: -21.5em -2.75em;
    width: 1em;
    height: 1em;
}
.fa-white-mobile {
    @extend %svg__default;
    background-position: -21.5em -4em;
    width: 1em;
    height: 1em;
}
.fa-white-percent {
    @extend %svg__default;
    background-position: -21.5em -5.25em;
    width: 1em;
    height: 1em;
}
.fa-white-phone {
    @extend %svg__default;
    background-position: -21.5em -6.5em;
    width: 1em;
    height: 1em;
}
.fa-white-play-circle-o {
    @extend %svg__default;
    background-position: -21.5em -7.75em;
    width: 1em;
    height: 1em;
}
.fa-white-plus {
    @extend %svg__default;
    background-position: -21.5em -9em;
    width: 1em;
    height: 1em;
}
.fa-white-search {
    @extend %svg__default;
    background-position: -21.5em -10.25em;
    width: 1em;
    height: 1em;
}
.fa-white-shield {
    @extend %svg__default;
    background-position: -21.5em -11.5em;
    width: 1em;
    height: 1em;
}
.fa-white-star {
    @extend %svg__default;
    background-position: -21.5em -12.75em;
    width: 1em;
    height: 1em;
}
.fa-white-suitcase {
    @extend %svg__default;
    background-position: -21.5em -14em;
    width: 1em;
    height: 1em;
}
.fa-white-th-large {
    @extend %svg__default;
    background-position: -21.5em -15.25em;
    width: 1em;
    height: 1em;
}
.fa-white-times-circle {
    @extend %svg__default;
    background-position: -21.5em -16.5em;
    width: 1em;
    height: 1em;
}
.fa-white-times {
    @extend %svg__default;
    background-position: -21.5em -17.75em;
    width: 1em;
    height: 1em;
}
.fa-white-user {
    @extend %svg__default;
    background-position: -21.5em -19em;
    width: 1em;
    height: 1em;
}
.fa-white-users {
    @extend %svg__default;
    background-position: -21.5em -20.25em;
    width: 1em;
    height: 1em;
}
.fa-white-wishlist-o {
    @extend %svg__default;
    background-position: -0.25em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-cogs-white {
    @extend %svg__default;
    background-position: -1.5em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-file-plus {
    @extend %svg__default;
    background-position: -2.75em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-headset {
    @extend %svg__default;
    background-position: -4em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-heart {
    @extend %svg__default;
    background-position: -5.25em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-minus-circle {
    @extend %svg__default;
    background-position: -6.5em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-plus-circle-2 {
    @extend %svg__default;
    background-position: -7.75em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-plus {
    @extend %svg__default;
    background-position: -9em -21.5em;
    width: 1em;
    height: 1em;
}
.icomoon-user {
    @extend %svg__default;
    background-position: -10.25em -21.5em;
    width: 1em;
    height: 1em;
}
.image-rating-empty-star {
    @extend %svg__default;
    background-position: -11.5em -21.5em;
    width: 1em;
    height: 1em;
}
.image-rating-full-star {
    @extend %svg__default;
    background-position: -12.75em -21.5em;
    width: 1em;
    height: 1em;
}
.image-share-fb-hover {
    @extend %svg__default;
    background-position: -14em -21.5em;
    width: 1em;
    height: 1em;
}
.image-share-fb {
    @extend %svg__default;
    background-position: -15.25em -21.5em;
    width: 1em;
    height: 1em;
}
.image-share-mail-hover {
    @extend %svg__default;
    background-position: -16.5em -21.5em;
    width: 1em;
    height: 1em;
}
.image-share-mail {
    @extend %svg__default;
    background-position: -17.75em -21.5em;
    width: 1em;
    height: 1em;
}
.image-usp-center-left {
    @extend %svg__default;
    background-position: -19em -21.5em;
    width: 1em;
    height: 1em;
}
.image-usp-center-right {
    @extend %svg__default;
    background-position: -20.25em -21.5em;
    width: 1em;
    height: 1em;
}
.image-usp-left {
    @extend %svg__default;
    background-position: -21.5em -21.5em;
    width: 1em;
    height: 1em;
}
.image-usp-right {
    @extend %svg__default;
    background-position: -22.75em -0.25em;
    width: 1em;
    height: 1em;
}
.kg-10000 {
    @extend %svg__default;
    background-position: -22.75em -1.5em;
    width: 1em;
    height: 1em;
}
.kg-10190 {
    @extend %svg__default;
    background-position: -22.75em -2.75em;
    width: 1em;
    height: 1em;
}
.kg-10306 {
    @extend %svg__default;
    background-position: -22.75em -4em;
    width: 1em;
    height: 1em;
}
.kg-10431 {
    @extend %svg__default;
    background-position: -22.75em -5.25em;
    width: 1em;
    height: 1em;
}
.kg-10444 {
    @extend %svg__default;
    background-position: -22.75em -6.5em;
    width: 1em;
    height: 1em;
}
.kg-10560 {
    @extend %svg__default;
    background-position: -22.75em -7.75em;
    width: 1em;
    height: 1em;
}
.kg-10639 {
    @extend %svg__default;
    background-position: -22.75em -9em;
    width: 1em;
    height: 1em;
}
.kg-10719 {
    @extend %svg__default;
    background-position: -22.75em -10.25em;
    width: 1em;
    height: 1em;
}
.kg-10723 {
    @extend %svg__default;
    background-position: -22.75em -11.5em;
    width: 1em;
    height: 1em;
}
.kg-14020 {
    @extend %svg__default;
    background-position: -22.75em -12.75em;
    width: 1em;
    height: 1em;
}
.kg-14021 {
    @extend %svg__default;
    background-position: -22.75em -14em;
    width: 1em;
    height: 1em;
}
.kg-14022 {
    @extend %svg__default;
    background-position: -22.75em -15.25em;
    width: 1em;
    height: 1em;
}
.kg-14510 {
    @extend %svg__default;
    background-position: -22.75em -16.5em;
    width: 1em;
    height: 1em;
}
.kg-14847 {
    @extend %svg__default;
    background-position: -22.75em -17.75em;
    width: 1em;
    height: 1em;
}
.kg-15346 {
    @extend %svg__default;
    background-position: -22.75em -19em;
    width: 1em;
    height: 1em;
}
.kg-15710 {
    @extend %svg__default;
    background-position: -22.75em -20.25em;
    width: 1em;
    height: 1em;
}
.kg-15752 {
    @extend %svg__default;
    background-position: -22.75em -21.5em;
    width: 1em;
    height: 1em;
}
.kg-15859 {
    @extend %svg__default;
    background-position: -0.25em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-17000 {
    @extend %svg__default;
    background-position: -1.5em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19000 {
    @extend %svg__default;
    background-position: -2.75em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19004 {
    @extend %svg__default;
    background-position: -4em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19005 {
    @extend %svg__default;
    background-position: -5.25em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19007 {
    @extend %svg__default;
    background-position: -6.5em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19008 {
    @extend %svg__default;
    background-position: -7.75em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19012 {
    @extend %svg__default;
    background-position: -9em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19013 {
    @extend %svg__default;
    background-position: -10.25em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19014 {
    @extend %svg__default;
    background-position: -11.5em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-19015 {
    @extend %svg__default;
    background-position: -12.75em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-brands {
    @extend %svg__default;
    background-position: -14em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-homepage {
    @extend %svg__default;
    background-position: -15.25em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-wof-blush {
    @extend %svg__default;
    background-position: -16.5em -22.75em;
    width: 1em;
    height: 1em;
}
.kg-wof {
    @extend %svg__default;
    background-position: -17.75em -22.75em;
    width: 1em;
    height: 1em;
}
.other-flag_dk {
    @extend %svg__default;
    background-position: -19em -22.75em;
    width: 1em;
    height: 1em;
}
.other-flag_no {
    @extend %svg__default;
    background-position: -20.25em -22.75em;
    width: 1em;
    height: 1em;
}
.other-flag_se {
    @extend %svg__default;
    background-position: -21.5em -22.75em;
    width: 1em;
    height: 1em;
}
.pe-angle-down {
    @extend %svg__default;
    background-position: -22.75em -22.75em;
    width: 1em;
    height: 1em;
}
.pe-angle-left {
    @extend %svg__default;
    background-position: -24em -0.25em;
    width: 1em;
    height: 1em;
}
.pe-angle-right {
    @extend %svg__default;
    background-position: -24em -1.5em;
    width: 1em;
    height: 1em;
}
.pe-angle-up {
    @extend %svg__default;
    background-position: -24em -2.75em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-checked-disabled {
    @extend %svg__default;
    background-position: -24em -4em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-checked-focus {
    @extend %svg__default;
    background-position: -24em -5.25em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-checked-focus_white {
    @extend %svg__default;
    background-position: -24em -6.5em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-checked {
    @extend %svg__default;
    background-position: -24em -7.75em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-checked_white {
    @extend %svg__default;
    background-position: -24em -9em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-unchecked-disabled {
    @extend %svg__default;
    background-position: -24em -10.25em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-unchecked-focus {
    @extend %svg__default;
    background-position: -24em -11.5em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-unchecked-focus_white {
    @extend %svg__default;
    background-position: -24em -12.75em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-unchecked {
    @extend %svg__default;
    background-position: -24em -14em;
    width: 1em;
    height: 1em;
}
.pe-checkbox-unchecked_white {
    @extend %svg__default;
    background-position: -24em -15.25em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-checked-disabled {
    @extend %svg__default;
    background-position: -24em -16.5em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-checked-focus {
    @extend %svg__default;
    background-position: -24em -17.75em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-checked {
    @extend %svg__default;
    background-position: -24em -19em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-unchecked-disabled {
    @extend %svg__default;
    background-position: -24em -20.25em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-unchecked-focus {
    @extend %svg__default;
    background-position: -24em -21.5em;
    width: 1em;
    height: 1em;
}
.pe-radiobtn-unchecked {
    @extend %svg__default;
    background-position: -24em -22.75em;
    width: 1em;
    height: 1em;
}
.stockstatus-incomingstock {
    @extend %svg__default;
    background-position: -0.25em -24em;
    width: 1em;
    height: 1em;
}
.stockstatus-instock {
    @extend %svg__default;
    background-position: -1.5em -24em;
    width: 1em;
    height: 1em;
}
.stockstatus-outofstock {
    @extend %svg__default;
    background-position: -2.75em -24em;
    width: 1em;
    height: 1em;
}
.stockstatus-prebook {
    @extend %svg__default;
    background-position: -4em -24em;
    width: 1em;
    height: 1em;
}
.stockstatus-prereleaseincomingstock {
    @extend %svg__default;
    background-position: -5.25em -24em;
    width: 1em;
    height: 1em;
}
.icomoon-arrow-down3 {
    @extend %svg__default;
    background-position: -15.65625em -12.21875em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-arrow-left4 {
    @extend %svg__default;
    background-position: -6.5em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-arrow-right4 {
    @extend %svg__default;
    background-position: -7.25em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-cancel-circle-2 {
    @extend %svg__default;
    background-position: -8em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-checkmark3-yellow {
    @extend %svg__default;
    background-position: -8.75em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-checkmark3 {
    @extend %svg__default;
    background-position: -9.5em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-cross2 {
    @extend %svg__default;
    background-position: -10.25em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-grid2-grey {
    @extend %svg__default;
    background-position: -11em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-grid2-white {
    @extend %svg__default;
    background-position: -11.75em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-grid2 {
    @extend %svg__default;
    background-position: -12.5em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-list-grey {
    @extend %svg__default;
    background-position: -13.25em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-list-white {
    @extend %svg__default;
    background-position: -14em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-list {
    @extend %svg__default;
    background-position: -14.75em -24em;
    width: 0.5em;
    height: 0.5em;
}
.icomoon-user-check2 {
    @extend %svg__default;
    background-position: -15.5em -24em;
    width: 0.5em;
    height: 0.5em;
}
