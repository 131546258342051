#block-content {
    #checkout .button-checkout {
        font-weight: normal;
    }

    .rr {
        &.horizontal-bar .items-container .items .item-container .rr-buy-area {
            height: 57px;
        }
    }
}
